import React, { useEffect, useRef, useState } from "react"
import Fuse from "fuse.js";

import "./styles-perimeter.scss";
import CitiesModel from "../../../model/Cities";

const SearchCity: React.FC<{ name?: string, placeholder?: string, onChange?: any, selectedItem?: any, customClass?: string }> = ({ name, placeholder, onChange, selectedItem, customClass }) => {

    const refSearch = useRef(null);

    const [search, setSearch] = useState<string>('');

    const [searchEngine, setSearchEngine] = useState<any>(null);

    const [searchCount, setSearchCount] = useState<number>(0);

    const [searchResults, setSearchResults] = useState<any>([]);

    const [toggleResult, setToggleResult] = useState(false);

    const [searchItems, setSearchItems] = useState([]);

    const searchOptions = {
        includeScore: true,
        includeMatches: true,
        useExtendedSearch: true,
        isCaseSensitive: false,
        threshold: 0.1,
        keys: [
            {
                name: 'city',
                weight: 5
            }
        ]
    }

    const onChangeSearch = async (value: string) => {
        let results: any[] = [];

        setSearch(value);
        if (!!value && 2 < value.length) {
            results = await searchEngine.search(value.trim().split(/,/).join(' | '));
            results = results.map(r => r.item);
        } else {
            results = [];
        }

        setSearchCount(results.length);
        setSearchResults(results);
        setToggleResult(true);
    }

    const onClickOption = (city: string) => {
        setSearch('');
        setToggleResult(false);
        onChange(city);
    }

    const NoOptionsMessage = () => {
        return (
            <span className="no-data">
                <b>Pas de résultat</b>
            </span>
        );
    };

    const handeClick = (e: any) => {
        switch (e.target.className) {
            case 'select-option':
                setToggleResult(false);
                break;
            default:
                setToggleResult((!!refSearch.current && !!refSearch.current.contains(e.target)));
        }
    }

    useEffect(() => {
        let setObj = new Set(); // create key value pair from array of array

        const cities = CitiesModel.reduce((acc, item) => {
            if (!setObj.has(item.city)) {
                setObj.add(item.city, item)
                acc.push(item)
            }
            return acc;
        }, []);

        setSearchEngine(new Fuse(cities, searchOptions));
        // setSearchItems(CitiesModel);
        document.addEventListener('click', handeClick, false);

        return () => {
            document.removeEventListener('click', handeClick, false);
        }
    }, []);

    return (
        <div ref={refSearch} className={`form_inner ${customClass}`}>
            <div className="form-row row row-block row-autocomplete city">
                <div className={`input-wrapper filters ${!!selectedItem ? 'has-value' : ''}`}>
                    <input type="text" className={`input-filter ${!!selectedItem ? 'has-value' : ''}`} name={name} autoComplete={name} placeholder={!!selectedItem ? selectedItem : placeholder} value={search} onFocus={(e) => onChangeSearch(e.currentTarget.value)} onChange={(e) => onChangeSearch(e.currentTarget.value)} autoComplete="off" />
                    {2 < search.length &&
                        <div className={`search-results-perimeter search-results ${(!!toggleResult) ? "search-open" : null}`}>
                            <div className="search-results_inner">
                                <div className="search-results-group">
                                    {
                                        0 < searchResults.length ?
                                            searchResults.map((sr: any) => (
                                                <div key={`${search}-${sr.city}`} className="search-results-subgroup" onClick={() => onClickOption(sr.city)}>
                                                    <label>{sr.city}</label>
                                                </div>
                                            ))
                                            : (!!search) ? <NoOptionsMessage /> : null
                                    }
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default SearchCity;