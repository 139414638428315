import React, { useEffect, useRef, useState } from "react"
import Fuse from "fuse.js";

import { uniqBy } from "../../../utils/Data";
import * as dataQPV from '../../../../datas/qpv.json'
import slugify from "slugify";

const SearchQPV: React.FC<{ placeholder?: string, onChange?: any, selectedItem?: any, attrReadOnly?: any }> = ({ placeholder, onChange, selectedItem, attrReadOnly }) => {

    const refSearch = useRef(null);

    const [search, setSearch] = useState<string>('');

    const [searchEngine, setSearchEngine] = useState<any>(null);

    const [searchCount, setSearchCount] = useState<number>(0);

    const [searchResults, setSearchResults] = useState<any>([]);

    const [toggleResult, setToggleResult] = useState(false);

    const [searchItems, setSearchItems] = useState([]);

    const searchOptions = {
        includeScore: true,
        includeMatches: true,
        useExtendedSearch: true,
        isCaseSensitive: false,
        threshold: 0.1,
        keys: [
            {
                name: 'label',
                weight: 5
            },
            // {
            //     name: 'value',
            //     weight: 1
            // },
            {
                name: 'groupLabel',
                weight: 3
            }
        ]
    }

    const onChangeSearch = async (value: string) => {
        let results: any[] = [];

        setSearch(value);
        if (!!value) {
            let tmp = await searchEngine.search(value.trim().split(/,/).join(' | '));

            //If same score for many results then order by groupLabel by ASC
            tmp = Object.entries(tmp).sort((a:any,b:any) => {
                return (a[1].score === b[1].score) ? parseInt(a[1].item.postalCode) - parseInt(b[1].item.postalCode) : true;
            }).map(s => s[1]);
  
            tmp.forEach((t: any) => {
                if (t.matches.find((m: any) => 'groupLabel' === m.key)) {
                    results.push(t.item.data);
                } else {
                    const to = {
                        label: t.item.data.label,
                        options: []
                    };
                    t.item.data.options.forEach((o: any) => {
                        if (o.label.toLowerCase().includes(value.toLowerCase().trim())) {
                            to.options.push(o);
                        }
                    });
                    results.push(to);
                }
            });

            results = uniqBy(results, (t: any) => t.label);
        } else {
            results = searchItems;
        }

        setSearchCount(results.length);
        setSearchResults(results);
        setToggleResult(true);
    }

    const onClickOption = (object: any) => {
        setToggleResult(false);
        onChange(object);
        setSearch('');
    }

    const NoOptionsMessage = () => {
        return (
            <span className="no-data">
                <b>Pas de résultat</b>
            </span>
        );
    };

    const handeClick = (e: any) => {
        switch (e.target.className) {
            case 'select-option':
                setToggleResult(false);
                break;
            default:
                setToggleResult((!!refSearch.current && !!refSearch.current.contains(e.target)));
        }
    }

    function compare(a: any, b: any) {
        // console.log("Compare");
        // console.log(a, b);
        return  slugify(a.label.toLowerCase()) > slugify(b.label.toLowerCase()) ? 1 : slugify(b.label.toLowerCase()) > slugify(a.label.toLowerCase()) ? -1 : 0;
    }

    const buildItems = () => {
        let newItems: any[] = [];
        let newItemsGroup: any = {};
        let groups: any[] = [];
        dataQPV.default.datas.forEach((el: any) => {
            el.cities.forEach((c: any) => {
                if ('undefined' === typeof newItemsGroup[c.city]) {
                    newItemsGroup[c.city] = {
                        label: `${c.city} (${c.postalCode})`,
                        postalCode: c.postalCode,
                        options: []
                    };
                    groups.push({
                        label: `${c.city} (${c.postalCode})`,
                        options: []
                    });
                }

                newItemsGroup[c.city].options.push({
                    // label: `${el.name} ${!!el.dpt_code ? `${(el.dpt_code)}` : ''}`,
                    label: `${el.name}`,
                    // value: el.code!
                    value: `${c.city} : ${el.name}`
                });
            })


            // if ('undefined' === typeof newItemsGroup[el.main_city]) {
            //     newItemsGroup[el.main_city] = {
            //         label: `${el.main_city} (${el.main_postcode})`,
            //         options: []
            //     };
            //     groups.push({
            //         label: `${el.main_city} (${el.main_postcode})`,
            //         options: []
            //     });
            // }

            // newItemsGroup[el.main_city].options.push({
            //     // label: `${el.name} ${!!el.dpt_code ? `${(el.dpt_code)}` : ''}`,
            //     label: `${el.name}`,
            //     value: el.code!
            // });
        })

        if (0 < Object.entries(newItemsGroup).length) {
            return Object.entries(newItemsGroup).map(ig => ig[1]);
        } else {
            return newItems.sort(compare);
        }
    }

    useEffect(() => {
        let tmp: any[] = [];

        const options = buildItems();

        options.forEach((o: any) => {
            o.options.forEach((oo: any) => {
                tmp.push({
                    label: oo.label,
                    value: oo.value,
                    groupLabel: o.label,
                    postalCode: o.postalCode,
                    data: o
                })
            })
        })

        setSearchEngine(new Fuse(tmp, searchOptions));
        setSearchItems(options);

        // if(!!selectedItem){
        //     setSearch(selectedItem.label);
        // }

    }, [dataQPV]);

    useEffect(() => {
        document.addEventListener('click', handeClick, false);

        return () => {
            document.removeEventListener('click', handeClick, false);
        }
    }, []);

    return (
        // <div className="input-wrapper">
            <div className="form_inner">
                <div className="form-row row row-block row-autocomplete">
                    <div className={`input-wrapper filters ${!!selectedItem ? 'has-value' : ''}`}>
                        <input type="text" {...attrReadOnly} className={`input-filter ${!!selectedItem ? 'has-value' : ''}`} name="search" placeholder={!!selectedItem ? selectedItem.label : placeholder} value={search} onFocus={(e) => onChangeSearch(e.currentTarget.value)} onChange={(e) => onChangeSearch(e.currentTarget.value)} autoComplete="spv" />
                        <div className={`search-results-perimeter search-results ${(!!toggleResult) ? "search-open" : "search-close"}  ${!!search ? 'not-empty' : 'is-empty'}`}>
                            <div className="search-results_inner">
                                <div className="search-results-group">
                                    {0 < searchResults.length ?
                                        searchResults.map((sr: any) => {
                                            return 0 < sr.options.length ?
                                                <div key={`${search}-${sr.label}`} className="search-results-subgroup">
                                                    <label>{sr.label}</label>
                                                    <ul>
                                                        {sr.options.map((o: any) => (
                                                            <li key={`${search}-${o.value}`} onClick={() => onClickOption(o)}>
                                                                <span className="select-option">
                                                                    {o.label}
                                                                </span>
                                                            </li>
                                                        ))
                                                        }
                                                    </ul>
                                                </div>
                                                : null
                                        })
                                        : (!!search) ? <NoOptionsMessage /> : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        // </div>
    );
}

export default SearchQPV;