import React, { useState, useRef } from "react";
import { useInView } from 'react-intersection-observer';
import Layout from "../../../../structure/Layout/Layout";
import Seo from "../../../../structure/Seo/Seo";
import CitySkyline from "../../../../element/Svg/CitySkyline";

import BlockInfo from "../../../../element/Editor/Blocks/BlockInfo/BlockInfo";

import "./styles.scss";
import "../../../../../../static/assets/css/react-tag.css";


import ReactTags from 'react-tag-autocomplete';
import { ValidateEmail } from "../../../../../services/EmailService";

import { DateTime } from "luxon";
import SearchQPV from "../../../../element/Select/SearchQPV";
import { MemberRegistration } from "../../../../../services/MemberService";
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css'; 
import SearchCity from "../../../../element/Select/SearchCity";


const MemberRegisterPage = () => {

  const maxBirthYear = DateTime.local().plus({ years: -18}).toFormat('yyyy');
  const minBirthYear = DateTime.local().minus({ years: 120}).toFormat('yyyy');
  
  const qpvTime = "spv_" + new Date().getTime(); 

  const maxChar = 1000;

  const [readOnlyMode, setReadOnlyMode] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const [errorLimitThematics, setErrorLimitThematics] = useState(null);
  const refTags: any = useRef<any>(null);

  const [errorMessage, setErrorMessage] = useState('');

  const [registerData, setRegisterData] = useState({
    gender: '',
    email: '',
    lastname: '',
    firstname: '',
    rulesAge: '',
    volunteer: '',
    domain: [],
    jobPosition: [],
    identity: [],
    thematics: [],
    introduction: '',
    birthYear: '',
    postalCode: '',
    address: '',
    city: '',
    phoneNumber: {
      valid: false,
      value: '',
      options: {}
    },
    qpv: {},
    noContact: [],
    noContactOther: '',
    valid: false
  });


  const [error, setError] = useState({
    email: '',
    lastname: '',
    firstname: '',
    rulesAge: '',
    gender: '',
    birthYear: '',
    jobPosition: '',
    identity: '',
    thematics: '',
    introduction: '',
    postalCode: '',
    address: '',
    city: '',
    phoneNumber: '',
    qpv: '',
    noContact: '',
    noContactOther: '',
    domain: ''
  });

  const [emailValid, setEmailValid] = useState<boolean>(false);

  const validateEmail = () => {
    if(!!registerData.email && '' === error.email){
      ValidateEmail(registerData.email)
      .then((response: any) => {
        if('OK' === response.statusText){
          setEmailValid((-1 !== ['Valid', 'Risky'].indexOf(response.data.result.verdict)));
        }else{
          setEmailValid(false);
        }      
      }, error => {
        setEmailValid(false);
        // console.log(error)
      })
    }
  }

  const onChangeData = (type: string, value: any, deleteData?: boolean): void => {
    const err = { ...error };
    setErrorMessage('');
    let data:any = {...registerData}

    switch (type) {
      case 'email':
        setEmailValid(false);
        err.email = '';
        if ('' === value) {
          err.email = 'Veuillez renseigner un e-mail';
        } else if (!value.match(/^[\w.!#$%&’*+/=?^_`{|}~-]+@[\.a-zA-Z0-9-]+\.[a-zA-Z0-9-]+$/)) {
          err.email = 'Votre e-mail est invalide';
        }
        break;
      case 'lastname':
      case 'firstname':
        err[type] = '';
        if ('' === value) {
          err[type] = 'Veuillez renseigner votre ' + ('lastname' === type ? 'nom' : 'prénom');
        } else if (!value.match(/^[\.a-zA-Z\-\séèëêçîïìüûùàâäöôÉÈËÊÇÎÏÌÜÛÙÀÂÄÖÔ\u0027]+$/)) {
          err[type] = 'Votre ' + ('lastname' === type ? 'nom' : 'prénom') + ' est invalide';
        }
      break;
      case 'birthYear':
        err[type] = '';

        if(!!value){
          if (!value.match(/^\d{4}$/) || value > maxBirthYear || value < minBirthYear ) {
            err[type] = 'Votre année de naissance est invalide';
          }
        }
      break;
      case 'jobPosition':
      case 'identity':
        err[type] = '';

        let tmp:any = [...data[type]];

        if(!!value.checked){
          tmp.push(value.value);
        }else{
          tmp.splice(tmp.indexOf(value.value), 1);
        }

        if (0 === tmp.length) {
          err[type] = 'Veuillez sélectionner au minimum une option';
        }
        value = tmp;
      break;
      case 'noContact':
        err[type] = '';

        let tmpC:any = [...data[type]];

        if(!!value.checked){
          tmpC.push(value.value);
        }else{
          // Reset error
          if('autre :' === value.value){
            err.noContactOther = '';
          }

          tmpC.splice(tmpC.indexOf(value.value), 1);
        }

        if (0 === tmpC.length) {
          err[type] = 'Veuillez sélectionner au minimum une option';
        }
        value = tmpC;
      break;
      case 'noContactOther':
        err[type] = '';
        if ('' === value) {
          err[type] = 'Veuillez renseigner votre créneau';
        }
      break;
      case 'thematics':
        err[type] = '';

        let tmpD:any = [...data[type]];

        if(3 === tmpD.length && !!value.checked){
          setErrorLimitThematics('Vous ne pouvez pas sélectionner plus de 3 options');
          setTimeout(() => {
            setErrorLimitThematics(null);
          }, 3000);
        }else{
          if(!!value.checked){
            tmpD.push(value.value);
          }else{
            tmpD.splice(tmpD.indexOf(value.value), 1);
          }

          if (0 === tmpD.length) {
            err[type] = 'Veuillez sélectionner au minimum une option';
          }
        }
        value = tmpD;
      break;
      case 'rulesAge':
        value = (value.checked) ? 1 : "";
        break;
      case 'rulesAgreement':
        value = (value.checked) ? 1 : '';
        break;
      case 'introduction':
        err[type] = '';

        if(0 === value.length){
          err[type] = 'Veuillez renseigner votre introduction';
        }
      break;
      case 'domain':
        err[type] = '';

        let tmpVS:any = [...data.domain];

        if (!!deleteData) {
          tmpVS.splice(value, 1);
        } else {
          if (0 === data.domain.length || (0 < data.domain.length && 0 === data.domain.filter((en: any) => en.name === value.name).length)) {
            tmpVS.push(value);
          }
        }
        value = tmpVS;
      break;
      case 'postalCode':
        err[type] = '';
        if('' === value){
          err[type] = 'Vous devez renseigner votre code postal';
        }else if (!value.match(/^\d{5}$/)) {
          err[type] = 'Votre code postale est invalide';
        }
      break;
      case 'phoneNumber':
        err[type] = '';
        if('' === value.value){
          err[type] = 'Vous devez renseigner votre numéro de téléphone';
        }else if (!value.valid) {
          err[type] = 'Votre numéro de téléphone est invalide';
        }
      break;
      case 'address':
        err[type] = '';
        if('' === value){
          err[type] = 'Vous devez renseigner votre adresse';
        }
      break;
      case 'city':
        console.log('city : ' + value);
        err[type] = '';
        if('' === value){
          err[type] = 'Vous devez renseigner votre ville';
        }
      break;
      default:

    }
    
    data = { ...data, ...{
        [type]: value
      }
    };

    data.valid = ('' === err.email && '' === err.lastname && '' === err.firstname && '' === err.gender && '' === err.birthYear && '' === err.identity && '' === err.thematics && '' === err.jobPosition &&
    '' === err.introduction && '' === err.address && '' === err.postalCode && '' === err.city && '' === err.phoneNumber && '' === err.noContact && '' === err.noContactOther &&
    !!data.rulesAge && !!data.gender && !!data.lastname && !!data.firstname && 0 < data.jobPosition.length && '' != data.volunteer && 0 < data.identity.length && 0 < data.thematics.length &&
    !!data.introduction && !!data.qpv && !!data.address && !!data.postalCode && !!data.city && !!data.phoneNumber.valid && 0 < data.noContact.length && (-1 === data.noContact.indexOf('autre :') || (-1 !== data.noContact.indexOf('autre :')  && !!data.noContactOther)));

    setRegisterData(data);
    setError(err);
  }

  const charLeft = () => {
    // let left = maxChar - registerData.introduction.replace(/\s/gi, '').length;
    let left = maxChar - registerData.introduction.length;
    
    if(0 > left){
      left = 0;
      setRegisterData({...registerData, ...{introduction: registerData.introduction.substring(0, (maxChar - 1))}});
    }

    return maxChar - left;
  }

  const onHandleInputBlur = () => {
    if(0 === refTags.current.props.tags.length && !!refTags.current.state.query){
      setError({...error, ...{domain: 'Appuyer sur valider pour enregistrer'}})
    }
  }

  const [attrReadOnly, setAttrReadOnly] = useState<any>(null);

  const changeReadOnlyMode = (mode:boolean) => {
    setReadOnlyMode(mode);
    
    if(!readOnlyMode) {
      setAttrReadOnly({readOnly:true, disabled:true});
      }else{
        setAttrReadOnly({readOnly:false, disabled:false});
      }
  }

  const onSubmit = (e: any) => {
    e.preventDefault();
  
    MemberRegistration(registerData)
    .then(response => {
      setConfirmation(true);
      setRegisterData({
        gender: '',
        email: '',
        lastname: '',
        firstname: '',
        rulesAge: '',
        volunteer: '',
        domain: [],
        jobPosition: [],
        identity: [],
        thematics: [],
        introduction: '',
        birthYear: '',
        postalCode: '',
        address: '',
        city: '',
        phoneNumber: {
          valid: false,
          value: '',
          options: {}
        },
        qpv: {},
        noContact: [],
        noContactOther: '',
        valid: false
      })

      setTimeout(() => {
        setEmailValid(false);
      //   setConfirmation(false);
        setAttrReadOnly({readOnly:false, disabled:false});

      }, 3000);

    }, error =>  console.log(error));
  }
  const [ref, inView] = useInView({
      threshold: 0.3,
      triggerOnce: false,
  });


  return (
    <Layout current='members'>
      <Seo title="Membre | Vu des Quartiers" />
      <div className="page page-grid page-cold page-member" data-theme="quaternary-25">
        <div className="page_inner">
          <section className="section section-main section-page section-header section-register">
            <div className="section_inner radius radius-small radius-right radius-bottom radius-left" data-theme="primary">
              <div className="section-title">
                <h1><b>Vous souhaitez<br /> devenir un contact&nbsp;?</b></h1>
              </div>
              <CitySkyline />
              <div className="section-content">
                <div className="section-intro">
                  <p>
                    <strong>
                      Merci de prendre 5 minutes pour répondre à toutes les questions, à commencer par votre mail. Les réponses à ce questionnaire ne seront visibles que par les journalistes accrédités.
                    </strong>
                  </p>
                  <p>
                    <strong>
                      Vous pouvez vous désinscrire à tout moment en nous contactant à l'adresse <a href="mailto:contact@vudesquartiers.fr" className="link link-inherit"><span className="link_label">contact@vudesquartiers.fr</span></a>
                    </strong>
                  </p>
                </div>


                <form id="formMember" className="form form-register" noValidate autoComplete="off" onSubmit={e=>e.preventDefault}>
                  {!confirmation &&
                  <div className="form_inner">
                    <div className="form-row row row-info">
                      <span className="clr-secondary"><strong><sup>*</sup>Champs obligatoires</strong></span>
                    </div>

                    <div className="form-row row row-block">
                      <label><span className="upp">Votre adresse e-mail<sup className="clr-secondary">*</sup></span></label>
                      <input type="email" {...attrReadOnly} name={qpvTime} value={registerData.email} pattern="" placeholder="ex: jeandelafontaine@vudesquartiers.org" autoComplete={qpvTime} onChange={(e) => onChangeData('email', e.currentTarget.value)} onBlur={validateEmail} />
                      {!!error.email &&
                        <div className="form-error" dangerouslySetInnerHTML={{ __html: error.email }}></div>
                      }
                    </div>

                    <div className="form-row row row-block row-checkbox">
                      <div className="input">
                        <input {...attrReadOnly} id="acceptAgeRules" type="checkbox" value={registerData.rulesAge} checked={!!registerData.rulesAge} name="rules_age" onChange={(e) => onChangeData('rulesAge', e.currentTarget)} />
                        <label className="checkbox_like" htmlFor="acceptAgeRules"></label>
                        <label className="checkbox_label" htmlFor="acceptAgeRules"><span>Pour remplir ce formulaire, vous devez confirmer avoir plus de 18&nbsp;ans</span></label>
                      </div>
                      {!!error.rulesAge &&
                        <div className="form-error" dangerouslySetInnerHTML={{ __html: error.rulesAge }}></div>
                      }
                    </div>

                    {(!!registerData.rulesAge && !!emailValid )&&
                      <div className="form form_step form_step-2">
                        <div className="form_group form_group-border">
                          <div className="form_group-title">
                            <span>Qui êtes-vous ?</span>
                          </div>
                          <div className="form-row row row-block">
                            <label><span className="upp">Votre prénom<sup className="clr-secondary">*</sup></span></label>
                            <input {...attrReadOnly} type="text" name={qpvTime} value={registerData.firstname} pattern="" placeholder="ex: Jean" autoComplete={qpvTime} onChange={(e) => onChangeData('firstname', e.currentTarget.value)} />
                            {!!error.firstname &&
                              <div className="form-error" dangerouslySetInnerHTML={{ __html: error.firstname }}></div>
                            }
                          </div>
                          <div className="form-row row row-block">
                            <label><span className="upp">Votre nom<sup className="clr-secondary">*</sup></span></label>
                            <input {...attrReadOnly} type="text" name={qpvTime} value={registerData.lastname} pattern="" placeholder="ex: de La Fontaine" autoComplete={qpvTime} onChange={(e) => onChangeData('lastname', e.currentTarget.value)} />
                            {!!error.lastname &&
                              <div className="form-error" dangerouslySetInnerHTML={{ __html: error.lastname }}></div>
                            }
                          </div>
                          <div className="form-row row row-block row-radio">
                            <div className="inputs inputs-inline">
                              <label><span className="upp">Votre sexe<sup className="clr-secondary">*</sup></span></label>
                              <div className="input">
                                <input {...attrReadOnly} id="genderFemale" type="radio" name="gender" value="Femme" onChange={(e) => onChangeData('gender', e.currentTarget.value)} />
                                <label className="radio_like" htmlFor="genderFemale"></label>
                                <label className="radio_label" htmlFor="genderFemale"><span>Femme</span></label>
                              </div>
                              <div className="input">
                                <input {...attrReadOnly} id="genderMale" type="radio" name="gender" value="Homme" onChange={(e) => onChangeData('gender', e.currentTarget.value)} />
                                <label className="radio_like" htmlFor="genderMale"></label>
                                <label className="radio_label" htmlFor="genderMale"><span>Homme</span></label>
                              </div>
                            </div>
                            {!!error.rulesAge &&
                            <div className="form-error" dangerouslySetInnerHTML={{ __html: error.rulesAge }}></div>
                            }
                          </div>
                          <div className="form-row row row-block">
                            <label><span>Votre année de naissance</span></label>
                            <input {...attrReadOnly} type="number" min={minBirthYear} max={maxBirthYear} step="1" name={qpvTime} value={registerData.birthYear} pattern="" placeholder="ex: 1980" autoComplete={qpvTime} onChange={(e) => onChangeData('birthYear', e.currentTarget.value)} />
                            {!!error.birthYear &&
                              <div className="form-error" dangerouslySetInnerHTML={{ __html: error.birthYear }}></div>
                            }
                          </div>
                        </div>
                        <div className="form_group form_group-border">
                          <div className="form_group-title">
                            <span>Que faites-vous ?</span>
                          </div>
                          <div className="form-row row row-block row-checkbox">
                            <div className="inputs">
                              <label><span className="upp">Vous êtes...<sup className="clr-secondary">*</sup></span></label>
                              <small className="fill-indication clr-primary-25">
                                <i className="onw-icon">{'->'}</i>
                                {!!readOnlyMode ? (
                                  <span>2 réponses maximum</span>
                                ) : (
                                  <span>plusieurs choix possibles</span>
                                )}
                              </small>
                              <div className="input">
                                <input {...attrReadOnly} id="jobPositionWorker" type="checkbox" name="jobPositionWorkder" value="salarié·e, entrepreneur·e, travailleur·euse indépendant·e" onClick={e => onChangeData('jobPosition', e.currentTarget)} />
                                <label className="checkbox_like" htmlFor="jobPositionWorker"></label>
                                <label className="checkbox_label" htmlFor="jobPositionWorker"><span>salarié·e, entrepreneur·e, travailleur·euse indépendant·e</span></label>
                              </div>
                              <div className="input">
                                <input {...attrReadOnly} id="jobPositionWorkless" type="checkbox" name="jobPositionWorkless" value="à la recherche d’un emploi" onClick={e => onChangeData('jobPosition', e.currentTarget)} />
                                <label className="checkbox_like" htmlFor="jobPositionWorkless"></label>
                                <label className="checkbox_label" htmlFor="jobPositionWorkless"><span>à la recherche d’un emploi</span></label>
                              </div>
                              <div className="input">
                                <input {...attrReadOnly} id="jobPositionRetirement" type="checkbox" name="jobPositionRetirement" value="à la retraite"  onClick={e => onChangeData('jobPosition', e.currentTarget)} />
                                <label className="checkbox_like" htmlFor="jobPositionRetirement"></label>
                                <label className="checkbox_label" htmlFor="jobPositionRetirement"><span>à la retraite</span></label>
                              </div>
                            </div>
                            {!!error.jobPosition &&
                              <div className="form-error" dangerouslySetInnerHTML={{ __html: error.jobPosition }}></div>
                            }
                          </div>


                          <div className={`form-row row row-block row-input-tags ${readOnlyMode ? 'readonly' : ''}`} data-tags={!!registerData.domain ? registerData.domain.length : 0}>
                            <label>
                            <span className="upp">Dans quel domaine ?</span>&nbsp;<span>
                              Précisez ici votre métier, fonction, etc. : après chaque terme ajouté, ajoutez une virgule pour le valider.
                            </span>
                            </label>
                              <small className="fill-indication clr-primary-25">
                                <i className="onw-icon">{'->'}</i>
                                {!!readOnlyMode ? (
                                  <span>Métier, fonction, secteur d'activité..…</span>
                                ) : (
                                    <span>plusieurs réponses possibles</span>
                                  )}
                              </small>
                           
                            <ReactTags
                              id='domain'
                              ref={refTags}
                              tags={registerData.domain}
                              placeholderText="ex : Métier, fonction, secteur d'activité..."
                              noSuggestionsText=''
                              removeButtonText=''
                              allowNew={true}
                              autoresize={true}
                              delimiters={['Enter', 'Tab', 'Comma', ',']}
                              onAddition={(tag: any) => onChangeData('domain', tag, false)}
                              onDelete={(tag: number) => onChangeData('domain', tag, true)}
                              onBlur={onHandleInputBlur}
                              {...attrReadOnly}
                            // classNames={
                            //   root: 'react-tags',
                            //   rootFocused: 'is-focused',
                            //   selected: 'react-tags__selected',
                            //   selectedTag: 'react-tags__selected-tag',
                            //   selectedTagName: 'react-tags__selected-tag-name',
                            //   search: 'react-tags__search',
                            //   searchWrapper: 'react-tags__search-wrapper',
                            //   searchInput: 'react-tags__search-input',
                            //   suggestions: 'react-tags__suggestions',
                            //   suggestionActive: 'is-active',
                            //   suggestionDisabled: 'is-disabled',
                            //   suggestionPrefix: 'react-tags__suggestion-prefix'
                            // }
                            />
                            {!!error.domain &&
                              <div className="form-error" dangerouslySetInnerHTML={{ __html: error.domain }}></div>
                            }
                          </div>

                          <div className="form-row row row-block row-radio">
                            <div className="inputs inputs-inline">
                              <label><span className="upp">êtes-vous bénévole dans une association ?<sup className="clr-secondary">*</sup></span></label>
                              <div className="input">
                                <input {...attrReadOnly} id="volunteerYes" type="radio" name="volunteer" value="1" checked={'1' === registerData.volunteer} onChange={e => onChangeData('volunteer', e.currentTarget.value)} />
                                <label className="radio_like" htmlFor="volunteerYes"></label>
                                <label className="radio_label" htmlFor="volunteerYes"><span>Oui</span></label>
                              </div>
                              <div className="input">
                                <input {...attrReadOnly} id="volunteerNo" type="radio" name="volunteer" value="0" checked={'0' === registerData.volunteer} onChange={e => onChangeData('volunteer', e.currentTarget.value)} />
                                <label className="radio_like" htmlFor="volunteerNo"></label>
                                <label className="radio_label" htmlFor="volunteerNo"><span>Non</span></label>
                              </div>
                            </div>
                          </div>


                          <div className="form-row row row-block row-checkbox">
                            <div className="inputs">
                              <label><span className="upp">Vous vous définissez comme...<sup className="clr-secondary">*</sup></span></label>
                              <small className="fill-indication clr-primary-25">
                                <i className="onw-icon">{'->'}</i>
                                {!!readOnlyMode ? (
                                  <span>plusieurs choix possibles</span>
                                ) : (
                                    <span>plusieurs choix possibles</span>
                                  )}
                              </small>
                              <div className="input">
                                <input {...attrReadOnly} id="identityResident" type="checkbox" name="identityH" value='Habitante, habitant' checked={(0 < registerData.identity.length && -1 !== registerData.identity.indexOf('Habitante, habitant'))} onChange={e => onChangeData('identity', e.currentTarget)} />
                                <label className="checkbox_like" htmlFor="identityResident"></label>
                                <label className="checkbox_label" htmlFor="identityResident"><span>habitante, habitant</span></label>
                              </div>
                              <div className="input">
                                <input {...attrReadOnly} id="identityActor" type="checkbox" name="identityA" value='Actrice, acteur (associatif, économique, élu...)' checked={(0 < registerData.identity.length && -1 !== registerData.identity.indexOf('Actrice, acteur (associatif, économique, élu...)'))} onChange={e => onChangeData('identity', e.currentTarget)}/>
                                <label className="checkbox_like" htmlFor="identityActor"></label>
                                <label className="checkbox_label" htmlFor="identityActor"><span>actrice, acteur (associatif, économique, élu...)</span></label>
                              </div>
                            </div>
                            {!!error.identity &&
                              <div className="form-error" dangerouslySetInnerHTML={{ __html: error.identity }}></div>
                            }
                          </div>

                          <div className="form-row row row-block row-checkbox">
                            <div className="inputs inputs-inline">
                              <label><span className="upp">Sur quelles thématiques souhaitez-vous principalement intervenir ?<sup className="clr-secondary">*</sup></span></label>
                              <small className="fill-indication clr-primary-25">
                                <i className="onw-icon">{'->'}</i>
                                  <span>3 réponses maximum</span>
                              </small>
                              <div className="input input-half">
                                <input {...attrReadOnly} id="thematicsC" type="checkbox" name="thematicsC" value="citoyenneté" checked={(0 < registerData.thematics.length && -1 !== registerData.thematics.indexOf('citoyenneté'))}  onChange={e => onChangeData('thematics', e.currentTarget)}/>
                                <label className="checkbox_like" htmlFor="thematicsC"></label>
                                <label className="checkbox_label" htmlFor="thematicsC"><span>Citoyenneté</span></label>
                              </div>
                              <div className="input input-half">
                                <input {...attrReadOnly} id="thematicsCE" type="checkbox" name="thematicsCE" value="commerce-entreprise" checked={(0 < registerData.thematics.length && -1 !== registerData.thematics.indexOf('commerce-entreprise'))} onChange={e => onChangeData('thematics', e.currentTarget)}/>
                                <label className="checkbox_like" htmlFor="thematicsCE"></label>
                                <label className="checkbox_label" htmlFor="thematicsCE"><span>Commerce-Entreprise</span></label>
                              </div>
                              <div className="input input-half">
                                <input {...attrReadOnly} id="thematicsCu" type="checkbox" name="thematicsCu" value="culture" checked={(0 < registerData.thematics.length && -1 !== registerData.thematics.indexOf('culture'))} onChange={e => onChangeData('thematics', e.currentTarget)}/>
                                <label className="checkbox_like" htmlFor="thematicsCu"></label>
                                <label className="checkbox_label" htmlFor="thematicsCu"><span>Culture</span></label>
                              </div>
                              <div className="input input-half">
                                <input {...attrReadOnly} id="thematicsE" type="checkbox" name="thematicsE" value="éductation" checked={(0 < registerData.thematics.length && -1 !== registerData.thematics.indexOf('éductation'))} onChange={e => onChangeData('thematics', e.currentTarget)}/>
                                <label className="checkbox_like" htmlFor="thematicsE"></label>
                                <label className="checkbox_label" htmlFor="thematicsE"><span>Éducation</span></label>
                              </div>
                              <div className="input input-half">
                                <input {...attrReadOnly} id="thematicsEm" type="checkbox" name="thematicsEm" value="emploi" checked={(0 < registerData.thematics.length && -1 !== registerData.thematics.indexOf('emploi'))} onChange={e => onChangeData('thematics', e.currentTarget)}/>
                                <label className="checkbox_like" htmlFor="thematicsEm"></label>
                                <label className="checkbox_label" htmlFor="thematicsEm"><span>Emploi</span></label>
                              </div>
                              <div className="input input-half">
                                <input {...attrReadOnly} id="thematicsJ" type="checkbox" name="thematicsJ" value="jeunesse" checked={(0 < registerData.thematics.length && -1 !== registerData.thematics.indexOf('jeunesse'))} onChange={e => onChangeData('thematics', e.currentTarget)}/>
                                <label className="checkbox_like" htmlFor="thematicsJ"></label>
                                <label className="checkbox_label" htmlFor="thematicsJ"><span>Jeunesse</span></label>
                              </div>
                              <div className="input input-half">
                                <input {...attrReadOnly} id="thematicsLU" type="checkbox" name="thematicsLU" value="logement/urbanisme" checked={(0 < registerData.thematics.length && -1 !== registerData.thematics.indexOf('logement/urbanisme'))} onChange={e => onChangeData('thematics', e.currentTarget)}/>
                                <label className="checkbox_like" htmlFor="thematicsLU"></label>
                                <label className="checkbox_label" htmlFor="thematicsLU"><span>Logement/Urbanisme</span></label>
                              </div>
                              <div className="input input-half">
                                <input {...attrReadOnly} id="thematicsM" type="checkbox" name="thematicsM" value="médiation" checked={(0 < registerData.thematics.length && -1 !== registerData.thematics.indexOf('médiation'))} onChange={e => onChangeData('thematics', e.currentTarget)}/>
                                <label className="checkbox_like" htmlFor="thematicsM"></label>
                                <label className="checkbox_label" htmlFor="thematicsM"><span>Médiation</span></label>
                              </div>
                              <div className="input input-half">
                                <input {...attrReadOnly} id="thematicsS" type="checkbox" name="thematicsS" value="santé" checked={(0 < registerData.thematics.length && -1 !== registerData.thematics.indexOf('santé'))} onChange={e => onChangeData('thematics', e.currentTarget)}/>
                                <label className="checkbox_like" htmlFor="thematicsS"></label>
                                <label className="checkbox_label" htmlFor="thematicsS"><span>Santé</span></label>
                              </div>
                              <div className="input input-half">
                                <input {...attrReadOnly} id="thematicsSe" type="checkbox" name="thematicsSe" value="sécurité" checked={(0 < registerData.thematics.length && -1 !== registerData.thematics.indexOf('sécurité'))} onChange={e => onChangeData('thematics', e.currentTarget)}/>
                                <label className="checkbox_like" htmlFor="thematicsSe"></label>
                                <label className="checkbox_label" htmlFor="thematicsSe"><span>Sécurité</span></label>
                              </div>
                              <div className="input input-half">
                                <input {...attrReadOnly} id="thematicsI" type="checkbox" name="thematicsI" value="insertion" checked={(0 < registerData.thematics.length && -1 !== registerData.thematics.indexOf('insertion'))} onChange={e => onChangeData('thematics', e.currentTarget)}/>
                                <label className="checkbox_like" htmlFor="thematicsI"></label>
                                <label className="checkbox_label" htmlFor="thematicsI"><span>Insertion</span></label>
                              </div>
                              <div className="input input-half">
                                <input {...attrReadOnly} id="thematicsSp" type="checkbox" name="thematicsSp" value="sport" checked={(0 < registerData.thematics.length && -1 !== registerData.thematics.indexOf('sport'))} onChange={e => onChangeData('thematics', e.currentTarget)}/>
                                <label className="checkbox_like" htmlFor="thematicsSp"></label>
                                <label className="checkbox_label" htmlFor="thematicsSp"><span>Sport</span></label>
                              </div>
                              <div className="input input-half">
                                <input {...attrReadOnly} id="thematicsVQ" type="checkbox" name="thematicsVQ" value="vie quotidienne" checked={(0 < registerData.thematics.length && -1 !== registerData.thematics.indexOf('vie quotidienne'))} onChange={e => onChangeData('thematics', e.currentTarget)}/>
                                <label className="checkbox_like" htmlFor="thematicsVQ"></label>
                                <label className="checkbox_label" htmlFor="thematicsVQ"><span>Vie quotidienne</span></label>
                              </div>
                              <div className="input input-half">
                                <input {...attrReadOnly} id="thematicsVE" type="checkbox" name="thematicsVE" value="vivre-ensemble" checked={(0 < registerData.thematics.length && -1 !== registerData.thematics.indexOf('vivre-ensemble'))} onChange={e => onChangeData('thematics', e.currentTarget)}/>
                                <label className="checkbox_like" htmlFor="thematicsVE"></label>
                                <label className="checkbox_label" htmlFor="thematicsVE"><span>Vivre-ensemble</span></label>
                              </div>
                            </div>
                            {!!error.thematics &&
                              <div className="form-error" dangerouslySetInnerHTML={{ __html: error.thematics }}></div>
                            }
                            {!!errorLimitThematics &&
                              <div className="form-error">
                                {errorLimitThematics}
                              </div>
                            }
                          </div>

                          <div className="form-row row row-block row-textarea">
                              <label><span className="upp">Quelques mots pour vous présenter :<sup className="clr-secondary">*</sup></span></label>
                              <small className="fill-indication clr-primary-25">
                                <i className="onw-icon">{'->'}</i>
                                <span>Présentez-vous en quelques mots. Ce qui vous semble le plus important à connaître sur vous... </span>
                              </small>
                              <div className="textarea">
                                <textarea {...attrReadOnly} name="introduction" placeholder="Votre présentation" maxLength={maxChar} value={registerData.introduction} onChange={e => onChangeData('introduction', e.currentTarget.value)}></textarea>
                                <div className="text-limiter" data-char={charLeft()}><span>{charLeft()}/{maxChar}</span></div>
                              </div>
                            {!!error.introduction &&
                              <div className="form-error" dangerouslySetInnerHTML={{ __html: error.introduction }}></div>
                            }
                          </div>
                        </div>
                        <div className="form_group form_group-border">
                          <div className="form_group-title">
                            <span>Où agissez-vous ? / Où habitez-vous</span>
                          </div>
                          <div className="form-row row row-block row-autocomplete glue">
                            <label>
                              <span className="upp">Dans quel quartier principal agissez-vous ou habitez-vous ?<sup className="clr-secondary">*</sup></span><br />
    
                            </label>
                            <SearchQPV
                                placeholder="ex: Toulouse, Grand Mirail"
                                onChange={(value: string) => onChangeData('qpv', value)}
                            />
                            {(!!registerData.qpv && !!registerData.qpv.label) &&
                            <div className="search-result-outside">
                              <span>{!!registerData.qpv ? 'Votre QPV : '+ registerData.qpv.label : ''}</span>
                            </div>
                          }
                          </div>
                          <div className="form-row row row-block row-title">
                            <h3><b>Votre adresse postale</b></h3>
                          </div>
                          <div className="form-row row row-block">
                            <label><span className="upp">N° et nom de voie<sup className="clr-secondary">*</sup></span></label>
                            <input {...attrReadOnly} type="text" name={qpvTime} value={registerData.address} pattern="" placeholder="ex: 1 rue de la mairie" autoComplete={qpvTime} onChange={e => onChangeData('address', e.currentTarget.value)} />
                            {!!error.address &&
                              <div className="form-error" dangerouslySetInnerHTML={{ __html: error.address }}></div>

                            }
                          </div>
                          <div className="form-row row row-block">
                            <label><span className="upp">Code postal<sup className="clr-secondary">*</sup></span></label>
                            <input {...attrReadOnly} type="number" name={qpvTime} value={registerData.postalCode} pattern="" placeholder="ex: 31500" autoComplete={qpvTime} maxLength="5" onChange={e => onChangeData('postalCode', e.currentTarget.value)} />
                            {!!error.postalCode &&
                              <div className="form-error">
                                {error.postalCode}
                              </div>
                            }
                          </div>
                          <div className="form-row row row-block row-autocomplete row-autocomplete-2">
                            <label><span className="upp">Commune<sup className="clr-secondary">*</sup></span></label>
                            {/* <input {...attrReadOnly} type="text" name={qpvTime} value={registerData.city} pattern="" placeholder="ex: Paris" autoComplete={qpvTime} onChange={e => onChangeData('city', e.currentTarget.value)} /> */}
                            <SearchCity
                             {...attrReadOnly}
                              name={qpvTime}
                              placeholder="ex: Toulouse"
                              selectedItem={registerData.city}
                              onChange={(city: string) => onChangeData('city', city)}
                            />

                            {!!error.city &&
                              <div className="form-error">
                                {error.city}
                              </div>
                            }
                          </div>
                        </div>
                        <div className="form_group form_group-border">
                          <div className="form_group-title">
                            <span>Comment vous contacter ?</span>
                          </div>
                          <div className="form-row row row-block row-dropdown">
                            <label><span className="upp">Numéro de téléphone<sup className="clr-secondary">*</sup></span></label>
                            <IntlTelInput
                              {...attrReadOnly}
                              defaultCountry="fr"
                              preferredCountries={['fr']}
                              onlyCountries={['fr', 'gf', 'pf', 're', 'mf', 'yt', 'mq', 'gp']}
                              placeholder="ex: 6 43 75 45 69"
                              value={registerData.phoneNumber.value} 
                              onPhoneNumberChange={(valid:any, value:any, options:any) => onChangeData('phoneNumber', {valid, value, options})}
                              // onPhoneNumberBlur={(valid:any, value:any, options:any) => onChangeData('phoneNumber', {valid, value, options})}
                            />
                            {/* <input {...attrReadOnly} type="tel" name={qpvTime} value={registerData.phoneNumber} pattern="" placeholder="ex: 06 43 75 45 69" autoComplete={qpvTime} maxLength="10" onChange={e => onChangeData('phoneNumber', e.currentTarget.value)} /> */}
                            {!!error.phoneNumber &&
                              <div className="form-error">
                                {error.phoneNumber}
                              </div>
                            }
                          </div>
                          <div className="form-row row row-block row-checkbox">
                            <div className="inputs">
                              <label><span className="upp">Les créneaux auxquels <b>vous ne souhaitez pas</b><br /> être contacté par des journalistes ?<sup className="clr-secondary">*</sup></span></label>
                              <small className="fill-indication clr-primary-25">
                                <i className="onw-icon">{'->'}</i>
                                <span>plusieurs choix possibles</span>
                              </small>
                              <div className="input">
                                <input {...attrReadOnly} id="noContactJourney" type="checkbox" name="noContactJourney" value='entre 22h et 7h' checked={-1 !== registerData.noContact.indexOf('entre 22h et 7h')} onChange={e => onChangeData('noContact', e.currentTarget)}/>
                                <label className="checkbox_like" htmlFor="noContactJourney"></label>
                                <label className="checkbox_label" htmlFor="noContactJourney"><span>Entre 22h et 7h</span></label>
                              </div>
                              <div className="input">
                                <input {...attrReadOnly} id="noContactWeekend" type="checkbox" name="noContactWeekend" value='le week-end' checked={-1 !== registerData.noContact.indexOf('le week-end')} onChange={e => onChangeData('noContact', e.currentTarget)}/>
                                <label className="checkbox_like" htmlFor="noContactWeekend"></label>
                                <label className="checkbox_label" htmlFor="noContactWeekend"><span>Le week-end</span></label>
                              </div>
                              <div className="input">
                                <input {...attrReadOnly} id="noContactOther" type="checkbox" name="noContactOther" value='autre :' checked={-1 !== registerData.noContact.indexOf('autre :')} onChange={e => onChangeData('noContact', e.currentTarget)}/>
                                <label className="checkbox_like" htmlFor="noContactOther"></label>
                                <label className="checkbox_label" htmlFor="noContactOther"><span>Autre :</span></label>
                                {-1 !== registerData.noContact.indexOf('autre :') && 
                                  <div className="input-child">
                                    <input {...attrReadOnly} type="text" name="noContactOtherInfo" value={registerData.noContactOther} pattern="" placeholder="Vos créneaux d'indisponibilité" autoComplete="off" onChange={e => onChangeData('noContactOther', e.currentTarget.value)} />
                                    {!!error.noContactOther &&
                                      <div className="form-error">
                                        {error.noContactOther}
                                      </div>
                                    }
                                  </div>
                                }
                              </div>
                            </div>
                            {!!error.noContact &&
                              <div className="form-error">
                                {error.noContact}
                              </div>
                            }
                          </div>
                        </div>
                        <div className="form_group">
                          <div className="form-row row row-block row-submit">

                            {!readOnlyMode && 
                            <a href="#bodySite" className={`btn btn-cta btn-secondary invert rounded ${!!confirmation ? 'state-ok' : ''}`}
                              data-disabled={!registerData.valid} 
                              type="button"
                              onClick={() => changeReadOnlyMode(true)}
                              data-uk-scroll
                              >
                              <span className="btn_label">Relecture du formulaire avant envoi</span>
                              <span className="btn_icon">
                                <i className="fas fa-arrow-right"></i>
                              </span>
                            </a>
                            }
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                  }
                  <div className={`marker-sticky ${inView ? '' : 'is-stick' }`} ref={ref}></div>
                    <div className={`form-actions ${inView ? '' : 'is-stick' } ${readOnlyMode ? 'readonly' : ''}`}>
                      <div className="form-actions_inner">
                      {(!!readOnlyMode && ! confirmation) && 
                      <>
                      <a href="#bodySite" className={`btn btn-cta btn-secondary invert rounded`}
                        onClick={() => changeReadOnlyMode(false)}
                        data-uk-scroll
                        >
                        <span className="btn_label">Modifier</span>
                        <span className="btn_icon">
                          <i className="fas fa-pen"></i>
                        </span>
                      </a>
                      <button className={`btn btn-cta btn-secondary invert rounded`}
                        type="submit"
                        disabled={!registerData.valid}
                        onClick={onSubmit}
                        >
                        <span className="btn_label">Valider ce formulaire</span>
                        <span className="btn_icon">
                          <i className="fas fa-arrow-right"></i>
                        </span>
                      </button>
                      </>
                      }
                      {(!!readOnlyMode && !!confirmation) && 
                        <button className={`btn btn-cta btn-quaternary btn-success invert rounded`} type="button">
                        <span className="btn_label">Formulaire envoyé</span>
                        <span className="btn_icon">
                          <i className="fas fa-check"></i>
                        </span>
                      </button>

                      }
                      </div>
                      {!!errorMessage &&
                        <div className="form-error">
                          {errorMessage}
                        </div>
                      }

                      {!!confirmation &&
                      <div className="bubble bubble-confirmation">
                        <div className="bubble_inner">
                          <div className="bubble__title">
                            Merci pour<br />
                            votre inscription :)
                          </div>
                          <div className="bubble__content">
                            <p>
                              L'équipe de Vu des Quartiers va étudier votre inscription et revient vers vous rapidement.
                            </p>
                          </div>
                        </div>
                      </div>
                      }
                    </div>



                </form>
                <BlockInfo
                  title={`Information RGPD`}
                  titleIcon={`fas fa-info`}
                  content={`
                  <p>
                  L'association Vue Des Quartiers traite les données recueillies pour gérer la liste des contacts proposée et des journalistes accrédités.<br>
                  Pour en savoir plus sur la gestion de vos données personnelles et pour exercer vos droits, <a href="/charte-d-utilisation" target="_blank" class="link link-inherit"><span class="link_label">reportez-vous à la notice</span></a>.
                  </p>
                  `}
                />

              </div>
            </div>
          </section>
        </div>
      </div>
    </Layout>
  );
}

export default MemberRegisterPage;