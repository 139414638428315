import axios from "axios";

const ValidateEmail = (email: string) => {
    return new Promise((resolve, reject) => {
        axios.post(`https://api.sendgrid.com/v3/validations/email`, {
            email: email,
            source: "member register"
        },
        {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${process.env.GATSBY_SENDGRID_EMAIL_VALIDATOR_KEY}`
            }
        })
        .then(response => {
            resolve(response);
        }, error => {
            reject(error);
        });
    });
}

export { ValidateEmail }