import React from "react";

import "./styles.scss";

interface BlockProps {
  title?: string,
  titleIcon?: string,
  content?: any,
}


const BlockInfo = ({
  title,
  titleIcon,
  content
}: BlockProps) => {

  return (
    <div className="block block-information">
      <div className="block block_inner">
        <h4><span className="block__label">{title}</span><span className="block__icon"><i className={`${titleIcon}`}></i></span></h4>
        <div className="block__content" dangerouslySetInnerHTML={{ __html: content }}></div>
      </div>
    </div>
  )
};


export default BlockInfo;